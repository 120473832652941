<template>
  <b-input-group class="form-currency-input" :size="size" prepend="$">
    <template #prepend><slot name="prepend"><b-input-group-text>$</b-input-group-text></slot></template>
    <b-form-input :value="currencyValue"
                  :type="type"
                  @change="change($event, true)"
                  @update="changeOnInput ? change($event, false) : () => {}"
                  :debounce="changeOnInput ? 400 : undefined"
                  :placeholder="placeholder"
                  :disabled="disabled"
                  @blur="$emit('blur', false)"
                  @focus="$emit('focus', true)"
                  @keyup.enter="$emit('enter')"
                  :state="isValid"
                  :autofocus="autofocus"
                  autocomplete="off"
                  :class="`text-${alignInput}`" />
    <template #append><slot name="append"></slot></template>
  </b-input-group>
</template>

<script>

export default {
  name: 'CurrencyInput',
  props: {
    value: [String, Number],
    precision: [String, Number],
    disabled: Boolean,
    validationCallback: Function,
    allowNull: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Dollar value'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    alignInput: {
      type: String,
      default: 'left'
    },
    type: {
      type: String,
      default: 'text'
    },
    changeOnInput: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number
    }
  },
  data () {
    return {
      currencyValue: '',
      currencyPrecision: 2
    }
  },
  mounted () {
    if (this.allowNull && this.value === null) {
      this.currencyValue = null

      return
    }

    if (this.precision) {
      this.currencyPrecision = parseFloat(this.precision)
    }
    this.currencyValue = this.$filters.currency(this.value, 2, { symbol: false })
  },
  watch: {
    value () {
      if (this.allowNull && this.value === null) {
        this.currencyValue = null
        return
      }
      if (!this.changeOnInput) this.currencyValue = this.$filters.currency(this.value, 2, { symbol: false })
    }
  },
  computed: {
    isValid () {
      if (this.validationCallback) {
        return this.validationCallback(this.currencyValue ? parseFloat(this.currencyValue.replace(/[$,]/g, '')) : this.currencyValue)
      } else {
        return null
      }
    }
  },
  methods: {
    change (newVal, format) {
      const clean = newVal.replace(/[$,]/g, '')

      if (this.allowNull && newVal === '') {
        this.$emit('input', null)

        return
      }
      if (format) this.currencyValue = this.$filters.currency(clean, 2, { symbol: false })
      const f = parseFloat(clean)
      if (typeof this.min === 'number' && typeof f === 'number' && f < this.min) {
        return
      }
      if (isNaN(f)) {
        return
      }
      this.$emit('input', f)
    }
  }
}
</script>

<style scoped>
</style>
